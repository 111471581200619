@import '../../styles';

.app {
  &-page-logged {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 550px;
    &__section {
      margin-top: 20px;
    }
  }

  &-section {
    &__create {
      @include box-default(17px 18px 23px);
    }
    &__transfert {
      @include box-default(17px 30px 29px 35px);
      min-height: 450px;
      max-height: 450px; //Sorito
    }
    &__certificates {
      @include box-default(34px 34px 0);
      min-height: 450px;
      max-height: 450px;
    }

    &__create {
      hr {
        border-top: 1px solid $border_color_light;
        margin: 52px -18px 19.5px;
      }
      p {
        @include font-style(13px, 19px, 600, -0.46px);
        font-family: $font-montserrat;
        margin-top: 2px;
        strong {
          display: block
        }
      }
      label {
        @include font-style(14px, 18px, 900, -0.5px);
        font-family: $font-montserrat;
        margin-left: 20px;
      }
      .app-toggle-panel__dialog {
        min-width: 414px;
      }
      .app-select__awardee {
        background: #FBFBFB;
      }
      .app-field__date {
        margin-top: 25px;
        margin-bottom: 41px;
      }
      &-preview {
        &.is-muted {
          p {
            strong {
              color: $color-text-muted;
              margin-bottom: 16px;
            }
          }
        }
        &.is-ready {
          p {
            strong {
              color: $color-black;
            }
            span {
              font-weight: 500;
              color: $color-neutral-800;
            }
          }
        }
        &.is-ready,
        &.is-muted {
          margin-top: 14px;
        }
      }
    }

    &__transfert {
      //Sorito
      overflow-y: auto;
      &.no-scroll {
        overflow-y: inherit;
      }
      p {
        @include font-style(14px, 18px, 900, -0.5px);
        font-family: $font-montserrat;
      }
      .app-card__certificate {
        margin-top: 25px;
      }
      .app-cta__token {
        margin-right: -14px;
      }
    }

    &__certificates {
      overflow-y: auto;
      //Sorito
      /*
      &.no-scroll {
        overflow: inherit;
      }*/
    }
  }

  &-nav {
    &__primary {
      @include box-default();
      margin-top: 41px;
      .app-cta {
        min-width: 30%;
      }
    }
    &__secondary {
      .app-cta {
        transition: $transition-link;
        margin-bottom: 17px;
        height: auto;
        font-weight: 900;
        color: #737373;
        &:hover,
        &.is-actived {
          color: #E42313;
        }
      }
    }
  }

  &-form {
    &__awardee {
      &-add {
        margin-top: 41px;
        .app-cta {
          margin-top: 23px;
        }
        .app-field--text {
          margin-top: 13px;
        }
      }
    }
    &__card {
      &-add,
      &-revoke,
      &-transfert {
        .app-cta {
          margin-top: 23px;
        }
      }
      &-create {
        .show .app-toggle-panel__content {
          margin-top: -45px;
        }
      }
      &-edit {
        .app-field--text,
        .app-field__date {
          margin-top: 13px;
        }
        .app-cta__submit {
          margin-top: 23px;
        }
        .app-field--text {
          input {
            min-width: 240px;
          }
        }
        .app-select__date {
          width: auto;
        }
      }
      &-revoke {
        p {
          @include field-label-config();
          text-align: center;
          color: #979797;
          margin: 60px 0;
        }
      }
      &-transfert {
        .app-field--text {
          input {
            min-width: 233px;
          }
        }
      }
    }
  }

  &-cta {
    &__view {
      border: 1px solid #0A5089 !important;
      color: #0A5089;
    }

    &__close {
      width: 273px;
      margin-top: 22px;
    }

    &__card-manage {
      min-width: 100px;
      height: auto;
      font-weight: 900;
      padding: 0 !important;
      color: #E42313;
      fill: #E42313;
      svg {
        margin: 0;
      }
    }

    &__card-transfert {
      min-width: 90px;
      height: auto;
      padding: 0 !important;
      font-weight: 900;
      color: #E42313;
      fill: #E42313;
    }

    &__token {
      background-color: #FEF3F3;
      font-weight: 600;
      span {
        margin-left: 6px;
        border-radius: 50%;
        background-color: #39890A;
        position: relative;
        height: 14px;
        width: 14px;
      }
    }
  }

  &-select {
    justify-content: space-between;
    font-weight: 900;
    .app-icon-select {
      position: absolute;
      right: 8px;
    }
    &__date {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.is-month {
        min-width: 134px;
      }
      &.is-year {
        margin-left: 14px;
        ///justify-content: center;
        min-width: 90px;
        width: auto;
      }
    }
    &__certificates {
      min-width: 218px;
    }
    &__date,
    &__certificates {
      padding-left: 17px;
      &.is-selected {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 6px;
        justify-content: flex-start;
        span {
          max-width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        svg {
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
    &__awardee {
      small {
        @include avatar-config();
        margin-right: 10px;
        margin-left: -8px;
      }
      .app-icon-select {
        right: 14px;
      }
      &.is-selected {
        justify-content: flex-start;
      }
    }
  }

  &-field {
    &--text {
      label {
        @include field-label-config();
      }
      input {
        @include field-input-text-config();
        min-height: 44px;
        padding: 0 16px;
      }
      span {
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 20px;
        margin-right: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        inset: 0 0 0 auto;
        &::after {
          content: "";
          inset: -5px;
          position: absolute;
          cursor: pointer;
        }
      }
    }

    &__awardee {
      .app-toggle-panel {
        width: 100%;
      }
    }

    &__date {
      label {
        @include font-style(14px, 18px, 900, -0,5px);
        font-family: $font-montserrat;
      }
    }
  }

  &-panel {
    &__add {
      &__awardee {
        height: 325px;
        .app-cta__back {
          background: transparent;
          height: auto;
          padding: 0;
          color: #E42313;
          fill: #E42313;
        }
      }
    }
    &__select {
      &__certificates,
      &__awardee,
      &__date {
        ul {
          li {
            position: relative;
            margin-top: 14px;
            button {
              @include reset-btn();
              transition: $transition-link;
              font-family: $font-montserrat;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              &:hover,
              &.is-actived {
                background-color: #DFDFDF;
                span {
                  color: $color-black;
                }
              }
              &.is-actived {
                span {
                  font-weight: 900;
                }
              }
              svg, small {
                margin-right: 6px;
              }
              small {
                @include avatar-config();
                margin-right: 10px;
              }
            }
          }
        }
      }
      &__certificates,
      &__awardee {
        height: 325px;
        overflow: auto;
        ul {
          margin-top: 43px;
          li {
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 67px;
            }
            button {
              @include box-default(6px);
              min-width: 340px;
              span {
                margin: 0;
                @include font-style(13px, 19px, 500, -0.46px);
              }
            }
          }
        }
        .app-cta {
          background: transparent;
          height: auto;
          padding: 0;
        }
      }
      &__certificates {
        position: relative;
      }
      &__awardee {
        .app-cta {
          color: #E42313;
          fill: #E42313;
        }
      }
      &__date {
        margin-bottom: 25px;
        &-list {
          margin-top: 10px;
          flex-wrap: wrap;
          .app-cta {
            width: 28%;
            margin: 14px 5px 0;
            &.is-actived,
            &:hover {
              background-color: #DFDFDF;
              span {
                color: $color-black;
              }
            }
          }
        }
      }
    }
    &__document-viewer {
      position: relative;
      margin: 10px 0;
    }
  }

  &-certificate {
    @include box-default(15px 18px 35px 19px);
    transition: $transition-tab;
    position: absolute;
    top: 145px;
    left: -55px;
    width: 561px;

    &.is-step-1 {
      padding: 66px 0 28px 0;
    }

    &__content {
      text-align: center;
    }

    &__document {
      svg {
        width: 524px;
        height: auto;
      }
    }

    &__notification {
      display: flex;
      align-items: center;
      border-radius: 12px;
      text-align: start;
      width: 414px;
      margin: 0 auto;
      padding: 16px 37px 21px 20px;
      background: #F1F1F1;
      color: #101010;
      svg {
        margin-right: 7px;
        width: 50px;
      }
      span {
        @include font-style(12px, 15px, 100, -0,6px);
        font-family: $font-montserrat;
      }
    }

    &__words {
      max-width: 422px;
      margin: 22px auto 20px;
      flex-wrap: wrap;
      .app-cta {
        width: auto;
        margin: 0 6.5px 12px;
        padding: 0 15px;
        font-weight: 500;
        &.is-actived,
        &:hover {
          background-color: #DFDFDF;
          span {
            color: $color-black;
          }
        }
      }
    }

    &__copy {
      @include font-style(14px, 18px, 900, -0,44px);
      font-family: $font-montserrat;
      color: #E42313;
    }

    &__legend {
      span {
        @include font-style(10px, 13px, 100, -0,31px);
        font-family: $font-montserrat;
      }
    }

    .app-cta__save {
      width: 332px;
      height: 61px;
      margin: 44px auto 15px;
    }
  }
}


$dot-color: #FD3739;
$dot-size: 20px;
$circle-size: 55px;

$animation-time: 4s;

.dots {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;

	filter: url(#goo);
}

.dot {
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;

	&:before {
		content: '';
		width: $dot-size;
		height: $dot-size;
		border-radius: 50%;
		background: $dot-color;
		position: absolute;
		left: 50%;
		top: -($circle-size);
		margin-left: -($dot-size/2);
		margin-top: -($dot-size/2);
	}

	@for $i from 0 to 11 {

		@keyframes dot-#{$i} {
			0% {
				transform: rotate(180deg + $i * (360deg / 11));
			}

			100% {
				transform: rotate(180deg + ($i + 1) * (5 * 360deg / 11) + $i * (360deg / 11));
			}
		}

		&:nth-child(#{$i + 1}) {
			transform: rotate($i * (360deg / 11));
			animation: dot-#{$i} $animation-time cubic-bezier(.3, .0, .7, 1) infinite;
		}
	}
}