@import '../../styles';

.app {
  &-cta-progress {
    @include cta-config();
    @include cta-size-large();
    @include cta-secondary();
    overflow: hidden;
    width: 100%;

    &__bar {
      position: absolute;
      transition: all 2.4s ease;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
    }

    &__message {
      
    }
  
    &.is-progress {
      border-color: #FA6828;
      color: #FA6828;
      .app-cta-progress__bar {
        background: #FA6828;
      }
    }
  
    &.is-success {
      border-color: #39890A;
      color: #39890A;
      .app-cta-progress__bar {
        background: #39890A;
        width: 100% !important;
      }
    }
  }
}
