@mixin box-default($padding: 12px 13px) {
  background: $bg_color_light;
  border: 1px solid $border_color_light;
  border-radius: $border_radius;
  padding: $padding;
  width: 100%;
}

@mixin avatar-config {
  @include font-style(12px, 14px, $font-bold, -1.71px);
  font-family: $font-montserrat;
  background: #eee;
  color: #ababab;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 31px;
  width: 31px;
}

@mixin cta-config {
  align-items: center;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  transition: $transition-link;
  border-radius: $border_radius;
  font-family: $font-montserrat-extra-bold;
  &:focus {
    outline: none;
    /*
    &::before {
      content: "";
      box-shadow: 0 0 0 3px $color-button-primary-hover;
      border-radius: $border_radius;
      position: absolute;
      left: -3px;
      right: -3px;
      top: -3px;
      bottom: -3px;
    }
    */
  }
  &:disabled {
    background-color: $color-button-primary-disabled;
    color: $color-text-muted;
    cursor: not-allowed;
  }
  &.is-block {
    width: 100%;
  }
  &.is-muted {
    color: $color-text-muted;
  }
}

@mixin cta-size-medium {
  @include font-style(14px, 18px, $font-bold, -0.5px);
  padding: 8px 7px;
  height: 42px;
}

@mixin cta-size-normal {
  @include font-style(14px, 18px, $font-bold, -0.5px);
  padding: 0 12px;
  height: 44px;
  &.has-icon-left {
    // justify-content: flex-start;
    svg {
      margin-right: 7px;
    }
  }
  &.has-icon-right {
    // justify-content: flex-end;
    svg {
      margin-left: 7px;
    }
  }
}

@mixin cta-size-large {
  @include font-style(16px, 19px, $font-bold, -0.6px);
  padding: 0 26px;
  height: 62px;
}

@mixin cta-primary {
  background: $color-button-primary;
  color: $color_white;
  &:not(:disabled) {
    &:hover {
      background: $color-button-primary-hover;
    }
  }
  .icon {
    fill: white;
  }
}

@mixin cta-secondary {
  background: $color-button-secondary;
  color: $color_black;
  &:not(:disabled) {
    border: 1px solid $border_color_light;
    /*
    &:hover {
      background: $color-button-secondary-inverse-hover;
    }
    */
    &.is-actived {
      background: $color-button-secondary-actived;
      border: 1px solid $color-neutral-600;
    }
  }
  .icon {
    fill: white;
  }
}

@mixin cta-neutral {
  background: $color-button-secondary;
  color: $color_black;
  &:not(:disabled) {
    border: 1px solid $border_color_light;
    /*
    &:hover {
      background: $color-button-secondary-inverse-hover;
    }
    */
    &.is-actived {
      background: $color-button-secondary-actived;
      border: 1px solid $color-neutral-600;
    }
  }
  .icon {
    fill: white;
  }
}

@mixin field-label-config {
  @include font-style(14px, 18px, 900, -0.5px);
  font-family: $font-montserrat;
}

@mixin field-input-text-config {
  @include reset-input();
  @include placeholder {
    @include font-smoothing(on);
    color: $color-text-muted;
  }
  @include font-style(14px, 18px, 500, -0.5px);
  font-family: $font-montserrat;
  border: 1px solid $border_color_light;
  border-radius: $border_radius;
}