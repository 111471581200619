@import '../../styles';

.app {
  // ------------------- //
  // CARD CERTIFICATE ------------------- //
  // ------------------- //
  &-card {
    &__certificate {
      @include box-default(20px 21px 16px);
      position: relative;
      margin-bottom: 25px;
      &.hidden {
        visibility: hidden;
      }
      p {
        font-family: $font-montserrat;
      }
      &__logo-brand {
        svg {
          max-width: 158px;
        }
      }
      &__logo-crypto {
        position: absolute;
        top: -16px;
      }
      &__details {
        margin-top: 22px;
        p {
          margin-left: 12px;
          strong {
            @include font-style(14px, 19px, 900, -0.5px);
            display: block;
          }
          span {
            @include font-style(13px, 14px, 600, -0.46px);
          }
        }
      }
      &__dates {
        margin-top: 36px;
        p {
          display: flex;
          &:last-child {
            margin-left: 28px;
          }
          strong {
            @include font-style(16px, 10px, 900, -0.57px);
            margin-left: 9px;
          }
          small {
            @include font-style(9px, 10px, 600, -0.32px);
            span {
              display: block;
              text-align: right;
            }
          }
        }
      }
      &__owner {
        margin-top: 27px;
        span {
          @include font-style(12px, 14px, $font-bold, -1.71px);
          font-family: $font-montserrat;
          background: #eee;
          color: #ababab;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 31px;
          width: 31px;
          margin-right: 10px;
        }
        p {
          strong {
            @include font-style(14px, 14px, 900, -0.5px);
          }
        }
      }
    }
  }
}
