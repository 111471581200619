@import '../../styles';

$zindex-modal:                      999 !default;
$transition-fade:                   opacity .15s linear !default;
$modal-fade-transform:              translate(0, 30px) !default;
$modal-show-transform:              none;
$modal-transition:                  all .2s ease-out !default;
$modal-dialog-margin:               .5rem !default;

.app {
  // ------------------- //
  // TOGGLE PANEL ------------------- //
  // ------------------- //
  &-toggle-panel {
    position: relative;
    &__dialog {
      position: absolute;
      z-index: $zindex-modal;
      left: 0;
      right: 0;
      // pointer-events: none;
      outline: 0;
      &.show { pointer-events: auto; }
      &.fade { pointer-events: none; }
    }
    &__content {
      @include box-default(24px 18px 15px);
      min-width: 376px;
      min-height: 100px;
      outline: 0;
      pointer-events: auto;
      background-clip: padding-box;
    }
  }
}

.fade {
  @include transition($transition-fade);
  &:not(.show) {
    overflow: hidden;
    opacity: 0;
    height: 0;
  }
  .app-toggle-panel__content {
    @include transition($modal-transition);
    transform: $modal-fade-transform;
    margin-top: 0;
  }
}

.show {
  .app-toggle-panel__content {
    margin-top: -15px;
    //height: auto;
  }
}