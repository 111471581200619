.app {
  &-grid {
    position: relative;
    display: flex;
    width: 100%;
    .dv {
      display: flex;
      width: 100%;
    }
    &--top {
      align-items: flex-start;
    }
    &--middle {
      align-items: center;
    }
    &--bottom {
      align-items: flex-end;
    }
    &--left {
      justify-content: flex-start;
    }
    &--center {
      justify-content: center;
    }
    &--right {
      justify-content: flex-end;
    }
    &--space-between {
      justify-content: space-between;
    }
    &--column {
      flex-direction: column;
    }
  }
}
