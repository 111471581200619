$color_white                      : #fff;
$color_black                      : #000;

$color-neutral-900                : #6D6D6D;
$color-neutral-800                : #5B5B5B;
$color-neutral-600                : #E7E7E7;
$color-neutral-500                : #DFDFDF;
$color-neutral-400                : #DBDBDB;
$color-neutral-300                : #CACACA;
$color-neutral-200                : #E6E6E6;
$color-neutral-100                : #E7E7E7;

/* THEME LIGHT */

$color_light                      : #131313;
$bg_color_light                   : $color_white;
$border_color_light               : $color-neutral-200;

$color-text-body                  : #131313;
$color-text-muted                 : $color-neutral-300;
$color-text-inverse               : $color_white;

/* BUTTON */

$color-button-primary             : #DF2127;
$color-button-primary-hover       : #ff2715;
$color-button-primary-pressed     : #DF2127;
$color-button-primary-disabled    : $color-neutral-100;

$color-button-secondary           : $color_white;
$color-button-secondary-actived   : $color-neutral-500;
$color-button-secondary-inverse-hover: $color-neutral-500;
$color-button-secondary-inverse-pressed: rgba(255, 255, 255, .8);

