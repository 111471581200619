@import '../../styles';

.app {
  // ------------------- //
  // CTA ------------------- //
  // ------------------- //
  &-cta {
    @include reset-btn();
    @include cta-config();
    &--size {
      &-medium {
        @include cta-size-medium();
      }
      &-normal {
        @include cta-size-normal();
      }
      &-large {
        @include cta-size-large();
      }
    }
    &--primary {
      @include cta-primary();
    }
    &--secondary {
      @include cta-secondary();
    }
    &--neutral {
      @include cta-neutral();
    }
    &::after {
      content: "";
      inset: -10px;
      position: absolute;
      cursor: pointer;
    }
  }
}
