@import '../../styles';

.app {
  &-layout {
    position: relative;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // ------------------- //
  // AREA ------------------- //
  // ------------------- //

  // Header ------------------- //

  &-header {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    h1 {
      @include font-style(47px, 40px, 700, -3.91667px);
      font-family: $font-montserrat-extra-bold;
      color: $color_light;
      span {
        display: block;
      }
    }

    &__col {
      &.is-left {
        padding: 0 0 0 20px;
      }
    }
  }

  // Body ------------------- //

  &-body {
    position: relative;
  }

  &-page {
    min-width: 450px;
  }

  // Footer ------------------- //

  &-footer {
    @include font-style(11px, 14px, false, -0,63px, $font-medium);
    font-family: $font-montserrat;
    color: $color-neutral-900;
    position: absolute;
    bottom: 28px;
  }
}
