*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  background: transparent;
  border: 0;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: none;
  }
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.svg-sprite {
  height: 1px;
  left: -1px;
  overflow: hidden;
  position: absolute;
  top: -1px;
  width: 1px;
  z-index: -1;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  background: #f2f2f2;
  color: #131313;

  &.dev {
    .dv {
      border: 1px solid red;
      padding: 5px;
      margin: 5px;
    }
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }  
}

// sass-lint:disable-block no-vendor-prefixes
@-ms-viewport {
  width: device-width;
}
