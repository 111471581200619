@import '../../styles';

.app {
  &-page-connect {
    @include box-default();
    margin-top: 54px;
    .app-cta__connect {
      max-width: 139px;
    }
  }
}

.iekbcc0.iekbcc9{
  font-family: 'Montserrat' !important;
  font-size: small !important;
  max-width: 139px !important;
  width: 139px !important;
  height: 44px !important;
  padding: 0 12px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  letter-spacing: -.5px !important;
  line-height: 18px !important;
  text-align: center !important;
  border-radius: 30px !important;
}

.iekbcc0.iekbcc9:hover{
  background-color: #ff2715 !important;
}

.iekbcc0.iekbcc9::after{
  --_12cbo8i1: 1 !important;
  --_12cbo8i0: 1 !important;
}

[data-rk] ._12cbo8i4, [data-rk] ._12cbo8i4::after {
  --_12cbo8i0: 1 !important;
}

[data-rk] ._12cbo8i6, [data-rk] ._12cbo8i6::after {
  --_12cbo8i1: 1 !important;
}