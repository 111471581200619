/*
  @mixin font-smoothing

  Enable or disable font-smoothing : this controls the application
  of anti-aliasing when fonts are rendered.
*/
@mixin font-smoothing($value: on) {
  // sass-lint:disable-block no-vendor-prefixes

  @if $value == on {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  } @else {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}
  
/*
  @mixin reset-btn

  Remove default browser style for button tag
*/
@mixin reset-btn {
  // sass-lint:disable-block no-vendor-prefixes
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

/*
  @mixin reset-input

  Remove default browser style for input tag
*/
@mixin reset-input {
  // sass-lint:disable-block no-vendor-prefixes

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  padding: 0;

  &::-ms-clear {
    display: none;
  }
}

/*
  @mixin media-container

  Display an image / video inside a wrapper with a defined ratio.
  his make sure the page is not jumping when images or video are being downloaded.

  Parameters:
  $ratio - Ratio of the media (like 9/16)
  */
@mixin media-container($ratio) {
  overflow: hidden;
  padding-bottom: percentage($ratio);
  position: relative;
  width: 100%;

  img,
  picture,
  video {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/*
  @mixin link-full-space

  To use when you want to extend the clickable zone for a link
*/
@mixin link-full-space {
  &::before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/*
  @mixin text-underline

  Parameters:
  $position - vertical position
  $underline - color of the underline
*/
@mixin text-underline($position: 1.1em, $underline: $color-link) {
  background-image: linear-gradient(to bottom, $underline, $underline);
  background-position: 0 $position;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  text-decoration: none;
}

/*
  @mixin keyline-fill

  Display a colored line on top / bottom of an element (without
  pushing its contents)

  Parameters:
  $position - vertical position
  $color - color
*/
@mixin keyline($position: top, $color: $color-stroke) {
  position: relative;

  &::before {
    border-top: 1px solid $color;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    z-index: 0;
    @if $position == top {
      bottom: 100%;
    } @else {
      top: 100%;
    }
  }
}


// stylelint-disable property-disallowed-list
@mixin transition($transition...) {
  @if length($transition) == 0 {
    $transition: $transition-base;
  }

  @if length($transition) > 1 {
    @each $value in $transition {
      @if $value == null or $value == none {
        @warn "The keyword 'none' or 'null' must be used as a single argument.";
      }
    }
  }

  @if nth($transition, 1) != null {
    transition: $transition;
  }

  @if nth($transition, 1) != null and nth($transition, 1) != none {
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}

/*
  @mixin placeholder

  Add specific cross-brower styling for the placeholder content of input tag

  ```scss
  input {
    @include placeholder {
      color: red;
    }
  }
  ```
*/
@mixin placeholder {
  // sass-lint:disable-block no-vendor-prefixes

  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}


/* Utility */

.no-outline {
  outline: none;
}

/* Screen readers */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Debug element */

body.dv {
  .dv {
    border: 1px solid red;
    padding: 5px;
  }
}
