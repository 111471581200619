// Font family
$font-montserrat: Montserrat;
$font-montserrat-extra-bold: Montserrat-ExtraBold, Montserrat;

// Font styles.
$font-bold: bold;
$font-italic: 'italic';
$font-medium: 'medium';
$font-regular: 'regular';

// // Set main fonts variables.
$font-sans-serif: 'Roboto', Arial, sans-serif;

@mixin font ($font-size: 13px, $font-weight: 500) {
  font-family: Roboto,"Helvetica Neue",sans-serif;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  font-size: $font-size;
  font-weight: $font-weight;
}

@mixin font-style($font-size: false, $line-height: false, $font-weight: false,
  $letter-spacing: false, $font-style: false, $text-transform: false) {
  // set font settings
  @if $font-size {
    font-size: $font-size;
  }
  @if $line-height {
    line-height: $line-height;
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }
  @if $font-style {
    font-style: $font-style;
  }
  @if $text-transform {
    text-transform: $text-transform;
  }
}
